import React from "react"
import { navigate } from "gatsby"
import { Form, Col, Button } from 'react-bootstrap'

class Submit extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
			valuesInText: null,
			sending: false,
			sendingDone: false,
		}
		this.handleSubmit.bind(this)
	}

	componentDidMount(){

		if( this.props.state !== null ){
			// 入力内容表示用の配列
			var valuesInText = []
			const formSettings = this.props.state.formSettings ? this.props.state.formSettings : null
			const values = this.props.state.values ? this.props.state.values : null

			for( let key in formSettings ){
				const label = formSettings[key].label
				const id = key

				// チェックボックスは選択地を配列で保存
				if( formSettings[id].type === "checkbox" ){
					const valueArray = []
					const options = formSettings[id].options
					options.map( option => {
						if( values[id][option.id] === true ) valueArray.push(option.label)
						return null
					})
					valuesInText.push({ label: label, value: valueArray })

				// 複数テキスト入力項目（横並び項目）
				}else if( formSettings[id].type === "text-group-flex" ){
					let valuemarged = ""
					const groups = formSettings[id].groups
					groups.map( group => {
						const labelprepend = group.labelprepend ? group.labelprepend : ""
						const labelappend = group.labelappend ? group.labelappend : ""
						const value = labelprepend + values[key][group.id] + labelappend + "　"
						valuemarged += value
						return null
					})
					valuesInText.push({ label: label, value: valuemarged })

				// 複数テキスト入力項目（縦並び項目）
				}else if( formSettings[id].type === "text-group-vertical" ){
					const valueArray = []
					const groups = formSettings[id].groups
					groups.map( group => {

						const labelprepend = group.labelprepend ? group.labelprepend : ""
						const labelappend = group.labelappend ? group.labelappend : ""
						const value = labelprepend + "　" + values[key][group.id] + " " + labelappend
						valueArray.push(value)
						return null
					})
					valuesInText.push({ label: label, value:  valueArray })

				// 単独入力項目
				}else{
					valuesInText.push({ label: label, value: values[key] })
				}
			}
			this.setState({ valuesInText: valuesInText })
		}
	}

	// メール送信
	handleSubmit() {

		// ボタンの無効化
		this.setState({ sending: true })

		let to							// 送信先
		let subject					// 件名
		let mailBodyTop			// 本文一行目

		//　お問い合わせページ
		if( this.props.state.pathname === "/contact/" ){
			to = process.env.CONTACT
			subject = "【お問い合わせ】グローバルワイズWEBサイトからのお問い合わせ"
			mailBodyTop = "グローバルワイズWEBサイトより、下記の内容でお問い合わせがありました。"
		}
		// 採用エントリー
		if( this.props.state.pathname === "/recruitApplication/" ){
			to = process.env.RECRUIT
			subject = "【採用エントリー】GlobalWiseホームページからの採用エントリー"
			mailBodyTop = "グローバルワイズWEBサイトより、採用エントリーを受け付けました。"
		}
		// AWSお問合せ
		if( this.props.state.pathname === "/awsContact/" ){
			to = process.env.AWSCONTACT
			subject = "【AWSお問い合わせ】グローバルワイズWEBサイトからのお問い合わせ"
			mailBodyTop = "グローバルワイズWEBサイトより、下記の内容でお問い合わせがありました。"
		}

		// 本文
		const mailBody = mailBodyConvert(mailBodyTop, this.state.valuesInText)

		const postData = new FormData()
		postData.set('to', to)
		postData.set('subject', subject)
		postData.set('body', mailBody)
		postData.set('from', process.env.FROM)

		fetch(process.env.MAILMODULE, {
      method: 'POST',
			body: postData
		})
		.then(res => res.json())
    .then(res => {

			// 送信完了
			if( res ){
				navigate("/thanks")
			} else{
				// 送信失敗
				alert('送信中にエラーが発生しました。\nしばらく時間を置いてから再度お試しください。')
				navigate(this.props.state.pathname)
			}
		})
		.catch( error => {
          console.log(error)
		})
	}

	// 確認画面に遷移
	handleFix(){
		navigate(
			this.props.state.pathname,
			{
				state: {
					values: this.props.state.values
				}
			}
		)
	}

	render(){
		return(
			<Form>
				{ this.state.valuesInText && this.state.valuesInText.map( valueInText => {
					const value = !Array.isArray(valueInText.value) ? valueInText.value : (
						valueInText.value.map( ( text, index ) => {
							return <span key={index}>{text}<br/></span>
						})
					)
					return (
						<Form.Row
							key={valueInText.label}
							className="align-items-top text-left"
						>
							<Col lg={4} xs={12} className="text-muted">
								<p>{valueInText.label}</p>
							</Col>
							<Col lg xs={12}>
								<p>{value}{ !value && <span className="text-muted">―</span> }</p>
							</Col>
							<Col lg={12}>
								<hr className="dots-hr" />
							</Col>
						</Form.Row>
					)
				})}

				<div className="text-center mt-5">
					<Button
						variant="info"
						className="my-5 mr-4"
						disabled={this.state.sending}
						onClick={()=>this.handleFix()}
					>
						入力画面に戻る
					</Button>
					<Button
						variant="info"
						className="my-5"
						disabled={this.state.sending}
						onClick={()=>this.handleSubmit()}
					>
						送信する
					</Button>
					<input type="hidden" id="mailresult" />
				</div>
				<Loader show={this.state.sending}/>
			</Form>
		)
	}
}

export default Submit

// メール本文作成
function mailBodyConvert (mailBpdyTop, values) {

	var body = "\n" + mailBpdyTop + "\n\n"
	values.map( value => {
		// 項目名
		body += "【" + value.label + "】\n"
		// 入力値
		if( value.value.length === 0 ){
			body += "記入無し\n\n"
		}else if( Array.isArray(value.value) ){
			value.value.map( text => {
				body += text + "\n"
				return null
      })
			body += "\n"
		}else{
			body += value.value + "\n\n"
		}
		return null
	})
	body += "----------------------------------------------------------\n\n"

	var today = new Date()
	body +=　
		"送信日：" +
		today.getFullYear() + "年" +
		(today.getMonth()+1) + "月" +
		today.getDate() + "日 " +
		today.getHours() + ":" +
		today.getMinutes() + "\n\n\n"

	return body
}

export const Loader = ({show}) => (
	<div key={`loader`} id="___loader"
		className={ show ? "" : "d-none" }
		style={{
			alignItems: "center",
			backgroundColor: "rgba(255,255,255,0.6)",
			display: "flex",
			justifyContent: "center",
			position: 'fixed',
			transition: "opacity 0.4s",
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
			zIndex: 100,
		}}
	>
		<div id="loader-inner" style={{textAlign: 'center'}}>
			<h3>送信中です<br/>しばらくお待ちください</h3>
			{/* ローディングアイコン */}
			<div id="loaderIcon" style={{display: 'inline-block'}}>
				<div className="ball-scale-ripple-multiple"><div></div><div></div><div></div></div>
			</div>
		</div>
	</div>
)