import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"
import Submit from "../components/submit"

const ContactSubmit = ({ location }) => {

	// 値の受け渡しなければTOP画面に遷移
	if (location.state === null) navigate("/")

	const data = useStaticQuery(graphql`
    query {
			infoIcon: file(relativePath: { eq: "icons/info.svg" }) {
        publicURL
      },
    }
	`)

	return (
		<LayoutExtra>
			<SEO title="お問い合わせ内容確認" />
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.infoIcon.publicURL} alt="お問い合わせ" />
					<h2>お問い合わせフォーム</h2>
					<p>Iinquiry From</p>
				</div>
				<p>
					以下の内容で間違いがなければ、「送信する」ボタンを押してください<br className="d-none d-lg-block" />
					修正される場合は「入力画面に戻る」ボタンを押してください。
				</p>
			</div>
			<Submit
				state={location.state}
			/>
		</LayoutExtra>
	)
}

export default ContactSubmit